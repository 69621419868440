import React from 'react';
import {
    Grid,
    List,
    Box,
    ListItem,
    ListItemText,
    Typography,
    Link,
    makeStyles,
    Hidden
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../i18n/functions';

const useStyles = makeStyles(theme => ({
    footer: {
        flexGrow: 1,
        background:
            "#f9f9f9 url('https://cdn1.acedms.com/photos/images/droomCredit/v2/footer-img.png')",
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        padding: theme.spacing(3),
        minHeight: theme.spacing(40),
        '& .Credit-MuiListItemText-root span': {
            fontSize: '14px',
            verticalAlign: 'top'
        }
    },
    footerTitle: {
        paddingLeft: theme.spacing(2),
        fontSize: theme.spacing(2.5),
        [theme.breakpoints.down(767)]: {
            paddingLeft: theme.spacing(0)
        }
    },
    footerList: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(0),
        '& .Credit-MuiListItem-root': {
            marginLeft: theme.spacing(3)
        },
        '& .Credit-MuiList-root:first-child .Credit-MuiListItem-root': {
            marginLeft: 0
        },
        '& .Credit-MuiList-root .Credit-MuiListItem-root': {
            paddingBottom: 5,
            paddingTop: 5
        },
        [theme.breakpoints.down(767)]: {
            '& .Credit-MuiList-root': {
                paddingTop: 0,
                paddingBottom: 0
            },
            '& .Credit-MuiList-root .Credit-MuiListItem-root': {
                marginLeft: 0,
                paddingLeft: 0
            }
        }
    },
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    socialLinks: {
        textAlign: 'right',
        [theme.breakpoints.down(767)]: {
            textAlign: 'left'
        }
    },
    socialFooterList: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        paddingTop: 0,
        [theme.breakpoints.down(767)]: {
            flexFlow: 'inherit',
            justifyContent: 'flex-start',
            paddingLeft: 0
        },
        '& > div > span': {
            textAlign: 'right',
            [theme.breakpoints.down(767)]: {
                textAlign: 'left'
            }
        }
    },
    socialIcons: {
        padding: theme.spacing(0, 0, 1.5, 1.5),
        [theme.breakpoints.down(767)]: {
            padding: theme.spacing(0, 1.5, 0, 0)
        }
    },
    socialFooterListLogo: {
        marginBottom: theme.spacing(3)
    }
}));

const linkContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0
};

const Footer = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation('footer');
    return (
        <footer className={classes.footer}>
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" className={classes.footerTitle}>
                            {t('quick links', 'Quick Links')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                        <Box className={classes.footerList}>
                            <List>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('how-it-works', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText
                                            primary={t("how it works", 'How it works')}
                                            className={classes.itemText}
                                        />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color="inherit" href={getLocalizedPath('about', i18n)} className={classes.link}>
                                        <ListItemText primary={t('about us', "About Us")} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('benefits', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('benefits', 'Benefits')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('in-the-press', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('In the press', 'In The Press')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('loan-documents', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Documents For Loan', 'Documents For Loan')} />
                                    </Link>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('contact-us', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Help', 'Help')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color="inherit" href={getLocalizedPath('faq', i18n)} className={classes.link}>
                                        <ListItemText primary={t('FAQ', 'FAQ')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('privacy-policy', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('privacy policy', 'Privacy Policy')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('terms-and-conditions', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('terms & conditions', 'Terms & Conditions')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('check-credit-score-free', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Free Credit Score', 'Free Credit Score')} />
                                    </Link>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('car-loan', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Car Loan', 'Car Loan')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('bike-loan', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Bike Loan', 'Bike Loan')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('scooter-loan', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Scooter Loan', 'Scooter Loan')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('emi-payment', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('EMI Payment Options', 'EMI Payment Options')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href="https://blog.droomcredit.com/"
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Blog', 'Blog')} />
                                    </Link>
                                </ListItem>
                            </List>
                            <List>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href={getLocalizedPath('contact-us', i18n)}
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('contact us', 'Contact Us')} />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color="inherit"
                                        href="https://sandeepaggarwal.com/"
                                        className={classes.link}
                                    >
                                        <ListItemText primary={t('Sandeep Aggarwal', 'Sandeep Aggarwal')} />
                                    </Link>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} sm={3} md={3} lg={1}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={3} md={3} lg={2}>
                        <List className={classes.socialLinks}>
                            <ListItem className={classes.socialFooterListLogo}>
                                <Link color="inherit" href={getLocalizedPath('/', i18n)}>
                                    <img
                                        src="https://cdn1.acedms.com/photos/images/droomCredit/v2/credit-logo-footer.png"
                                        alt="Droom Credit"
                                    />
                                </Link>
                            </ListItem>
                            <ListItem className={classes.socialFooterList}>
                                <ListItemText color="inherit">{t('follow us', 'Follow Us')}</ListItemText>
                            </ListItem>
                            <ListItem className={classes.socialFooterList}>
                                <List style={linkContainer}>
                                    <ListItem className={classes.socialIcons}>
                                        <Link
                                            rel="noopener"
                                            color="inherit"
                                            target="_blank"
                                            href="https://www.facebook.com/DroomCredit/">
                                            <img
                                                src="https://cdn1.acedms.com/photos/images/droomCredit/v2/facebook.png"
                                                alt="Fcebook"
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem className={classes.socialIcons}>
                                        <Link
                                            rel="noopener"
                                            color="inherit"
                                            target="_blank"
                                            href="https://twitter.com/droomcredit">
                                            <img
                                                src="https://cdn1.acedms.com/photos/images/droomCredit/v2/twitter1.png"
                                                alt="Twitter"
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem className={classes.socialIcons}>
                                        <Link
                                            rel="noopener"
                                            color="inherit"
                                            target="_blank"
                                            href="https://in.pinterest.com/droomcredit/">
                                            <img
                                                src="https://cdn1.acedms.com/photos/images/droomCredit/v2/pin.png"
                                                alt="Pinterest"
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem className={classes.socialIcons}>
                                        <Link
                                            rel="noopener"
                                            color="inherit"
                                            target="_blank"
                                            href="https://www.youtube.com/channel/UCHLGXHtk6zghl62IziNne5w">
                                            <img
                                                src="https://cdn1.acedms.com/photos/images/droomCredit/v2/you-tube.png"
                                                alt="Youtube"
                                            />
                                        </Link>
                                    </ListItem>
                                    <ListItem className={classes.socialIcons}>
                                        <Link
                                            rel="noopener"
                                            color="inherit"
                                            target="_blank"
                                            href="https://www.linkedin.com/company/droom-credit/">
                                            <img
                                                src="https://cdn1.acedms.com/photos/images/droomCredit/v2/linkedin.png"
                                                alt="Linkedin"
                                            />
                                        </Link>
                                    </ListItem>
                                </List>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="body2" style={{ textAlign: 'center' }}>
                            Copyright © 2020 Droom Credit. All rights reserved.
            </Typography>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
}

export default React.memo(Footer);
