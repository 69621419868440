import axios from 'axios';
import axiosRetry from 'axios-retry';

const retryDelay = (retryNumber = 0) => {
    const seconds = Math.pow(2, retryNumber) * 1000;
    const randomMs = 1000 * Math.random();
    return seconds + randomMs;
};

const addRetryOption = (_axios) => axiosRetry(_axios, {
    retries: 2,
    retryDelay,
    // retry on Network Error & 5xx responses
    retryCondition: axiosRetry.isRetryableError,
});


export default class Service {
    constructor(axiosConfig) {
        this.axiosConfig = axiosConfig;
        this.axios = axios.create({ ...this.axiosConfig });
        addRetryOption(this.axios);
    }

    // @see: https://github.com/axios/axios#interceptors
    interceptRequest(...args) {
        return this.axios.interceptors.request.use(...args);
    }

    interceptResponse(...args) {
        return this.axios.interceptors.response.use(...args);
    }

    requestResolve(config = {}) {
        return config;
    }

    requestReject(error) {
        return Promise.reject(error);
    }

    // @see: https://github.com/axios/axios#response-schema
    responseResolve(response) {
        return response;
    }

    responseReject(error) {
        return Promise.reject(error);
    }

    httpRedirect(path) {
        if (typeof window !== 'undefined') {
            window.location.replace(path);
        }
    }

    // @see: https://github.com/axios/axios#instance-methods
    delete(...args) {
        return this.axios.delete(...args);
    }

    get(...args) {
        return this.axios.get(...args);
    }

    post(...args) {
        //add csrf to post
        return this.axios.post(...args);
    }

    put(...args) {
        return this.axios.put(...args);
    }

    patch(...args) {
        return this.axios.patch(...args);
    }
}

const getBaseURL = () => {
    const host = process.env.WEB_HOST;
    const port = process.env.WEB_PORT;
    const origin = process.env.WEB_BASE_URL || `http://${host}:${port}`;

    return `${origin}`;
};


export const service = new Service({
    // baseURL: getBaseURL(),
    timeout: parseInt(process.env.REQUEST_TIMEOUT, 10)
});

service.interceptRequest(service.requestResolve, service.requestReject);
service.interceptResponse(service.responseResolve, service.responseReject);