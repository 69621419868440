export default {
    htmlAttributes: { lang: 'en', hreflang: "x-default" },
    titleTemplate: 'Vehicle Loan: Apply For Auto Loan Online at Lowest Interest Rates | Droom Credit',
    link: [
        {
            rel: 'alternate',
            href: 'https://droomcredit.com/hi_IN',
            hreflang: 'hi'
        },
        {
            rel: 'stylesheet dns-prefetch preconnect',
            href:
                'https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css',
        },
        {
            rel: 'icon',
            type: 'image/png',
            href: '/assets/icons/favicon.ico'
        },
        {
            rel: 'manifest',
            href: '/assets/manifest.json'
        },
        {
            rel: 'canonical',
            href: 'https://droomcredit.com',
        }
    ],
    meta: [
        {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
            name: 'name',
            content: 'Vehicle Loan: Apply For Auto Loan Online at Lowest Interest Rates | Droom Credit'

        },
        {
            name: 'description',
            content: 'Vehicle Loan- Apply auto loan online for a new or used vehicle to get instant approval with low interest rates.'
        },
        {
            name: 'image',
            content: 'https://cdn2.droom.in/imgusr/droomCredit/logo/logobeta.png'

        },
        {
            name: 'theme-color',
            content: '#1a1a1a'
        }
    ]
};
