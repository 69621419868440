import React from 'react';
import { Grid, CardMedia, Link, AppBar, Hidden, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Navigation, SwitchLanguage, SideDrawer } from './Navigation';
import emailIcon from '../../assets/images/email.png';
import creditLogo from '../../assets/images/credit-logo.png';
import callIcon from '../../assets/images/call.png';
import { getLocalizedPath } from '../i18n/functions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        padding: theme.spacing(2, 0),
        background: '#f9f9f9',
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(1, 2)
        }
    },
    headerDcLogo: {
        width: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100px'
        }
    },
    mainLink: {
        color: '#5b5b5b',
        '$:hover': {
            textDecoration: 'none'
        }
    },
    callIcon: {
        width: 13,
        height: 13,
        marginRight: 10,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    emailIcon: {
        width: 14,
        height: 10,
        marginRight: 10,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    switch: {
        textAlign: 'right'
    }
}));

const Header = () => {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const preventDefault = event => event.preventDefault();
    return (
        <AppBar className={classes.mainContainer}>
            <Grid container className={'container'}>
                <Grid item xs={4} sm={2}>
                    <Link href={getLocalizedPath('/', i18n)} className={classes.mainLink}>
                        <img
                            src={creditLogo}
                            alt="Droom Credit"
                            className={classes.headerDcLogo}
                        />
                    </Link>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} sm={4} md={3}>
                        <div className={classes.paper}>
                            <Typography variant="body2" component="div" color="primary">
                                <CardMedia
                                    className={classes.callIcon}
                                    image={callIcon}
                                    title="Call us"
                                    alt="Call us"
                                />
                                9650 08 08 08
                            </Typography>
                        </div>
                        <div className={classes.paper}>
                            <Link href="mailto:support@droomcredit.com" variant="body2">
                                <CardMedia
                                    className={classes.emailIcon}
                                    image={emailIcon}
                                    title="Droom Credit"
                                    alt="Droom Credit"
                                />
                                support@droomcredit.com
                            </Link>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={5}>
                        <Navigation />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SwitchLanguage />
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <div style={{ flexGrow: 1 }}></div>
                    <Grid item xs={2} sm={1} md={1}>
                        <SideDrawer />
                    </Grid>
                </Hidden>
            </Grid>
        </AppBar>
    );
}

export default React.memo(Header);