import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { ErrorBoundary } from '../components';

export default function Body({ route }) {
    return <ErrorBoundary>{renderRoutes(route.routes)}</ErrorBoundary>;
}

Body.propTypes = {
    route: PropTypes.object.isRequired
};
