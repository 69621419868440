import { isDev, syspath } from '../../config';
import { supportedLangs, fallbackLocale } from './common';

const BackendOptions = {
    loadPath: `${syspath.assets}/locales/{{lng}}/{{ns}}.json`,
    addPath: `${syspath.assets}/locales/{{lng}}/{{ns}}.missing.json`,
    // allow cross domain requests
    crossDomain: false,

    // allow credentials on cross domain requests
    withCredentials: false,

    // overrideMimeType sets request.overrideMimeType("application/json")
    overrideMimeType: false,

    requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default'
    },

    reloadInterval: false // can be used to reload resources in a specific interval (useful in server environments)
};

const DetectionOptions = {
    // order and from where user language should be detected
    order: ['customPathDetector', 'path'],

    // keys or params to lookup language from
    lookupFromPathIndex: 0,
};

export const options = {
    fallbackLng: fallbackLocale,
    debug: isDev,
    supportedLngs: Object.keys(supportedLangs),
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common', 'app', 'footer'],
    defaultNS: 'common',
    fallbackNS: 'common',
    saveMissing: false,
    saveMissingTo: 'current',

    //Backend Options
    backend: BackendOptions,

    //Detection Options
    detection: DetectionOptions,

    wait: process && !process.release,
};

//Language Detection Fuction for Frontend
export default {
    name: 'customPathDetector',
    async: true,
    lookup(options) {
        let found;
        if (typeof window !== 'undefined') {
            const language = window.location.pathname.match(/\/([a-zA-Z-_]*)/g);
            if (language instanceof Array) {
                if (typeof options.lookupFromPathIndex === 'number') {
                    if (typeof language[options.lookupFromPathIndex] !== 'string') {
                        return undefined;
                    }
                    found = language[options.lookupFromPathIndex].replace('/', '');
                } else {
                    found = language[0].replace('/', '');
                }
            }

        }
        return found;
    }
};