import types from './types';

export const userLoggedIn = (payload) => dispatch => {
    dispatch({ type: types.USER_LOGGED_IN, payload:payload });
};

export const userLoggedOut = () => dispatch => {
    dispatch({ type: types.USER_LOGGED_OUT });
};

export default {
    userLoggedIn,
    userLoggedOut
}