export const supportedLangs = {
    en_IN: {
        text: "English",
        fallbackTrans: "English",
        urlSlug: "/"
    },
    hi_IN: {
        text: "Hindi",
        fallbackTrans: "Hindi",
        urlSlug: "/hi_IN"
    }
};

export const fallbackLocale = "en_IN";