import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import { hydrate } from 'react-dom';
import { isDev } from '../config';
import configureStore from './redux/configureStore';
import registerOffline from './registerOffline';
import routes from './routes';
import theme from './theme/creditTheme';
import i18n from './i18n';
import { useSSR } from 'react-i18next';
import {
    ThemeProvider,
    StylesProvider,
    createGenerateClassName
} from '@material-ui/core/styles';
import { Loader } from './components';

const preloadedState = window.__UNIVERSSR_PRELOADED_STATE__;
const { store, history } = configureStore(preloadedState);

const generateClassName = createGenerateClassName({
    seed: 'Credit'
});

const BaseApp = (props) => {
    React.useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);
    useSSR(window.initialI18nStore, window.initialLanguage, i18n);
    return (
        <Suspense fallback={<Loader />}>
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <Frontload>{renderRoutes(props.AppRoutes)}</Frontload>
                        </ConnectedRouter>
                    </Provider>
                </ThemeProvider>
            </StylesProvider>
        </Suspense>
    )
};

const render = AppRoutes => {
    hydrate(
        <BaseApp AppRoutes={AppRoutes} />,
        document.getElementById('root')
    );
};

render(routes);

if (!isDev) {
    registerOffline();
}
