import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { helmet } from '../../config';
import { Header, Body, Footer } from '.';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fffdfd',
        color: '#5b5b5b',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        '& .container': {
            position: 'relative',
            maxWidth: '1100px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%'
            }
        },
        '& .wrapper': {
            marginTop: theme.spacing(5),
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(2.5),
            backgroundColor: '#f9f9f9'
        }
    },
    button: {
        textTransform: 'none'
    }
}));

export function Layout(props) {

    React.useEffect(() => {
        const jssStyles = document.getElementById('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Helmet {...helmet} />
            <Header {...props} />
            <Body {...props} />
            <Footer {...props} />
        </div>
    );
}

export default withRouter(Layout);
