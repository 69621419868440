import root from 'app-root-path';

export default {
    root,
    api: `${root}/api`,
    app: `${root}/app`,
    assets: `${root}/assets`,
    config: `${root}/config`,
    logger: `${root}/logger`,
    logs: `${root}/logs`,
    middleware: `${root}/middleware`,
    public: `${root}/public`,
    webpack: `${root}/webpack`
};
