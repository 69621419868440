import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DehazeIcon from '@material-ui/icons/Dehaze';
import user from '../../../assets/images/user.png';
import SwitchLanguage from './SwitchLanguage';
import {
    MenuItem,
    ListItemText,
    ListItem,
    List,
    Button,
    Drawer,
    Link,
    Menu,
    Divider
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { service } from '../../utils';
import { getLocalizedPath } from '../../i18n/functions';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../../redux/user';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #ddd',
      '& .Credit-MuiMenuItem-root': {
          minHeight: "auto"
      }
    },
  })((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableScrollLock= {true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
      {...props}
    />
));
const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
        margin: theme.spacing(0, 1),
        padding: theme.spacing(0),
        '& .Credit-MuiListItemText-root span': {
            fontSize: '13px'
        }
    },
    fullList: {
        width: 'auto'
    },
    loginLink: {
        position: 'relative',
        display: 'inline-block',
        textDecoration: 'none',
        color: '#fff',
        margin: theme.spacing(1.25, 1, 1.25, 2),
        padding: theme.spacing(0),
        verticalAlign: 'middle',
        height: 'auto',
        lineHeight: theme.spacing(1),
        background: '#575656',
        borderRadius: '4px',
        '& li': {
            padding: theme.spacing(0.50, 2.50)
        }
    },
    subLink: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontSize: 14,
        verticalAlign: 'middle'
    },
    listItemText: {
        position: 'relative',
        fontSize: 14,
        margin: 0,
        verticalAlign: 'top',
        cursor: 'pointer'
    },
}));

const TemporaryDrawer = ({ isLoggedIn, userData, actions }) => {
    const { t, i18n } = useTranslation('app');
    const classes = useStyles();
    const preventDefault = event => event.preventDefault();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [state, setState] = React.useState({
        left: false
    });

    const toggleDrawer = (anchor, open) => event => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [anchorSignUp, setAnchorSignUp] = React.useState(null);
    const handleClicksignUp = event => {
        setAnchorSignUp(event.currentTarget);
    };
    const handleSignUpClose = () => {
        setAnchorSignUp(null);
    };

    //login
    const [anchorLogin, setAnchorLogin] = React.useState(null);
    const handleClickLogin = event => {
        setAnchorLogin(event.currentTarget);
    };
    const handleLoginClose = () => {
        setAnchorLogin(null);
    };

    React.useEffect(() => {
        const checkUserData = () => {
            if(isLoggedIn === null || isLoggedIn !== JSON.parse(localStorage.getItem('isLoggedIn'))) {
                service.get(getLocalizedPath('get-session-data',i18n))
                    .then(response => {
                        if(typeof response.data !== 'undefined' && typeof response.data.data !== 'undefined' && typeof response.data.data.firstName !== 'undefined') {
                            localStorage.setItem('isLoggedIn', true);
                            actions.userLoggedIn(response.data.data);
                        } else {
                            localStorage.setItem('isLoggedIn', false);
                            actions.userLoggedOut();
                        }
                    })
                    .catch(err => console.log(err));
            }
        }

        //run only initailly
        if(isLoggedIn === null) {
            checkUserData();
        }

        window.addEventListener('storage', checkUserData);

        return () => {
            window.removeEventListener('storage', checkUserData);
        }
    }, [isLoggedIn]);

    const list = anchor => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <List>
                <Link href={getLocalizedPath('about', i18n)} className={classes.link}>
                    <ListItem className={classes.listItems}>
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('about', 'About')}
                        </ListItemText>
                    </ListItem>
                </Link>
                <Link to="#" className={classes.link}>
                    <ListItem
                        className={classes.listItems}
                        aria-controls="simpleMenu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <ListItemText classes={{ primary: classes.listItemText }}>
                            {t('Products', 'Products')}
                        </ListItemText>
                    </ListItem>
                    <StyledMenu
                        id="simpleMenu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <Link href={getLocalizedPath('car-loan', i18n)} className={classes.subLink}>
                                {t('Car Loan', 'Car Loan')}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link href={getLocalizedPath('bike-loan', i18n)} className={classes.subLink}>
                                {t('Bike Loan', 'Bike Loan')}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link href={getLocalizedPath('scooter-loan', i18n)} className={classes.subLink}>
                                {t('Scooter Loan', 'Scooter Loan')}
                            </Link>
                        </MenuItem>
                    </StyledMenu>
                </Link>
                {
                isLoggedIn
                    ?
                    <Link className={classes.loginLink}>
                        <ListItem
                            className={classes.listItems}
                            aria-controls="Login"
                            aria-haspopup="false"
                            onClick={handleClickLogin}
                        >
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                {userData.firstName}
                            </ListItemText>
                        </ListItem>
                        <StyledMenu
                            id="login"
                            anchorEl={anchorLogin}
                            keepMounted
                            open={Boolean(anchorLogin)}
                            onClose={handleLoginClose}
                        >
                            <MenuItem onClick={handleLoginClose}>
                                <Link href={userData.isLender ? '/lender-dashboard' : getLocalizedPath('account/loan-applications', i18n)} className={classes.subLink}>
                                    {t('My Account', 'My Account')}
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleLoginClose}>
                                <Link href={getLocalizedPath('logout', i18n)} className={classes.subLink}>
                                    {t('Logout', 'Logout')}
                                </Link>
                            </MenuItem>
                        </StyledMenu>
                    </Link>
                    :
                    <>
                    <Link to="#" className={classes.link}>
                        <ListItem
                            className={classes.listItems}
                            aria-controls="signUp"
                            aria-haspopup="true"
                            onClick={handleClicksignUp}
                        >
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                <img className={classes.myIcon} src={user} alt="SignUp" /> {t('Sign Up', 'Sign Up')}
                            </ListItemText>
                        </ListItem>
                        <StyledMenu
                            id="signUp"
                            anchorEl={anchorSignUp}
                            keepMounted
                            open={Boolean(anchorSignUp)}
                            onClose={handleSignUpClose}
                        >
                            <MenuItem onClick={handleSignUpClose}>
                                <Link href={getLocalizedPath('lenders', i18n)} className={classes.subLink}>
                                    {t('Sign Up As Lender', 'Sign Up As Lender')}
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleSignUpClose}>
                                <Link href={getLocalizedPath('apply-loan', i18n)} className={classes.subLink}>
                                    {t('Sign Up As Borrower', 'Sign Up As Borrower')}
                                </Link>
                            </MenuItem>
                        </StyledMenu>
                    </Link>
                    <Link href={getLocalizedPath('login', i18n)} className={classes.link}>
                        <ListItem className={classes.listItems}>
                            <ListItemText classes={{ primary: classes.listItemText }}>
                                {t('Login', 'Login')}
                            </ListItemText>
                        </ListItem>
                    </Link>
                </>
                }
            </List>
            <Divider />
            <List>
                <SwitchLanguage />
            </List>
        </div>
    );

    return (
        <div>
            {['left'].map(anchor => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <DehazeIcon />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}

const mapStateToProps = state => ({
    isLoggedIn: state.user.isLoggedIn,
    userData: state.user.userData
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryDrawer);

TemporaryDrawer.propTypes = {
    isLoggedIn: PropTypes.bool,
    userData: PropTypes.object.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired
}