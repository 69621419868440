import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import customPathDetector, { options } from './i18n/reactOptions';


const languageDetector = new LanguageDetector();
languageDetector.addDetector(customPathDetector);

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
    i18n
        .use(Backend)
        .use(initReactI18next)
        .use(languageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
    i18n.init(options);
}

export default i18n;